/* @import url(//fonts.googleapis.com/earlyaccess/notosansjp.css); */
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
*:focus {
  outline: none;
}
* {
  margin: 0;
  padding: 0;
}
html {
  font-size: 75%;
}
body {
  font-size: 1.2rem;
  font-family: 'Noto Sans JP', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333333;
  line-height: 1;
  background-color: #fff;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  font-size: 1.4rem;
}
a {
  text-decoration: none;
  color: #16b2d9;
  cursor: pointer;
}
a:visited {
  color: #16b2d9;
}
a:hover {
  color: #1898d6;
}
a:active {
  color: #333333;
}
ul {
  list-style-type: none;
}
button {
  border: none;
  font-weight: bold;
}

/*region form */
input[type='text'],
.input {
  border: solid 1px #adadad;
  border-radius: 2px;
  padding: 0.8rem;
  font-size: 1.4rem;
  transition: 0.3s ease;
  border-radius: 0.3rem;
  height: 3rem;
}
/* /selection/:id page　の　キャンセル期限と選考開催日時のel-date-pickerの幅を小さくする。
  el-data-pickerに直接cssスタイルを追加しても反映されない */
.value > .el-input {
  width: 150px !important;
}

input:focus {
  background: #fefff2;
}
.el-input__icon.el-icon-date,
.el-icon-circle-close {
  line-height: normal;
}
select {
  border: solid 1px #9d9d9d;
  border-radius: 2px;
  font-size: 1.4rem;
}

/*これはChromeのautofillを消すためのハック的なものでうs*/
input:-webkit-autofill {
  transition: background-color 9999s;
}

textarea {
  width: 100%;
  resize: none;
  height: 10rem;
}
.form-appearance-none {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  font-size: 1.4rem;
}
/*endregion*/

/*region Layout */
.flexbox {
  display: -webkit-flex;
  display: flex;
}
.flexbox-middle {
  align-items: center;
}
.margin-left-auto {
  margin-left: auto;
}
.width-app-limit {
}
/*endregion*/

/* ブラウザ選択不可 */
.un-selectable {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.pos-relative {
  position: relative;
}
.pos-absolute {
  position: absolute;
}

/*region table Layout*/
table.table-normal {
  border-collapse: collapse;
  width: 100%;
  font-size: 1.2rem;
}
table.table-normal th,
table.table-normal td {
  padding-left: 2rem;
  line-height: 4rem;
  border-left: solid 1px #d9d9d9;
}
table.table-normal th:last-child,
table.table-normal td:last-child {
  border-right: none;
}
table.table-normal thead {
  text-align: left;
}
table.table-normal thead tr {
  border-top: dashed 1px #d9d9d9;
  border-bottom: dashed 1px #d9d9d9;
}
table.table-normal thead tr th {
  font-weight: normal;
}
table.table-normal tbody {
}
table.table-normal tbody tr {
  border-bottom: dashed 1px #d9d9d9;
}
table.table-normal tbody tr th {
  border-right: solid 1px #d9d9d9;
}
table.table-normal tbody tr th:last-child {
  border-right: none;
}
/*endregion*/

/*region Butoon */

.headline-typeA {
  padding: 15px;
  color: #fff;
  font-size: 1.4rem;
  font-weight: bold;
  background-color: #4397d1;
}
.headline-typeB {
  padding-bottom: 10px;
  font-size: 1.4rem;
  font-weight: bold;
  border-bottom: solid 3px #e6e6e6;
}
.headline-typeC {
  padding-bottom: 20px;
  font-size: 1.4rem;
  font-weight: bold;
}
.headline-typeD {
  padding-bottom: 10px;
  font-size: 1.2rem;
  font-weight: bold;
}

.btn-centerArea {
  text-align: center;
}

.btn-menu {
  position: relative;
}

.button-regist {
  position: relative;
  padding: 10px 30px 10px 15px;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
}
.button-regist > span {
  pointer-events: none;
}
.button-regist > .arrow {
  position: absolute;
  top: 14px;
  right: 10px;
  width: 10px;
  color: #16b2d9;
}

.btns-area .btn {
  margin-right: 6px;
  margin-top: 10px;
}

.btn {
  display: inline-block;
  padding: 10px 15px;
  border-radius: 4px;
  text-align: center;
  color: #fff !important;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease-out;
}
.btn .icon {
  height: 14px;
  margin-right: 3px;
  vertical-align: -2px;
}
.btn .icon-leftArrow {
  height: 10px;
  margin-right: 5px;
  vertical-align: -1px;
}
.btn .icon-rightArrow {
  height: 10px;
  margin-left: 5px;
  vertical-align: -1px;
}

.btn-s {
  padding: 6px 10px 7px;
  border-radius: 4px;
  text-align: center;
  color: #fff !important;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease-out;
}
.btn-s .icon {
  height: 10px;
  vertical-align: baseline;
}

.btn-availability {
  background-color: #16b2d9;
}
.btn-availability:hover {
  background-color: #1698d9;
}

.btn-border {
  color: #16b2d9 !important;
  background-color: #fff;
  border: 1px solid #16b2d9;
  transition: all 0.3s ease-out;
}
.btn-border:hover {
  color: #1698d9 !important;
  background-color: #fff;
  border-color: #1698d9;
}
.btn-border:disabled {
  color: #333 !important;
  border-color: #999;
  transition: all 0s;
}

.btn-unavailable {
  background-color: #999;
}
.btn-unavailable:hover {
  background-color: #666;
}

.btn-runtime {
  background-color: #12b312;
}
.btn-runtime:hover {
  background-color: #007300;
}

.btn-caution {
  background-color: #bf0000;
}
.btn-caution:hover {
  background-color: #8c0707;
}

.btn-swicher {
  width: 100%;
  background-color: #bbb;
}
.btn-swicher.active {
  background-color: #e0ae16;
}
.btn-swicher + .btn-swicher {
  margin: 10px 0 0;
}

.btn-search {
  background: #1899d6;
  color: white;
  line-height: 3rem;
  border-radius: 0.4rem;
  height: 3rem;
}

.btn-sizefull {
  width: 100%;
  padding: 15px 10px;
}

.subBtn {
  padding: 10px 0;
  border-radius: 4px;
  text-align: center;
  color: #16b2d9;
  font-weight: bold;
  background-color: #efefef;
}
.subBtn > .arrow {
  width: 6px;
}
.subBtn > .icon {
  width: 8px;
  vertical-align: 0;
}

/*endregion*/

/*region Transition*/
.ease-enter-active,
.ease-leave-active {
  transition: opacity 0.3s ease;
}
.ease-enter,
.ease-leave-to {
  opacity: 0;
}
/*endregion*/

.basic-box-shadow {
  position: absolute;
  box-sizing: border-box;
  background-color: white;
  box-shadow:
    0 6px 12px 0 rgba(0, 0, 0, 0.06),
    0 0 4px 0 rgba(0, 0, 0, 0.06);
}

.under-bar-hi-light.blank {
  min-width: 100px;
  height: 2rem;
}

.primary_button {
  background-color: #359fcf;
  color: white;
  font-weight: bold;
  font-size: 1.4rem;
  padding: 8px;
}
.ghost_button {
  background-color: rgba(255, 355, 255, 0);
  color: #359fcf;
  border-color: #359fcf;
  font-weight: bold;
  font-size: 1.4rem;
  padding: 8px;
}

.normal_button {
  border: 1px solid #000000;
  border-radius: 4px;
  padding: 4px 8px 4px;
  font-weight: bold;
  font-size: 1.4rem;
}

.important_button {
  background-color: #cf3582;
  color: white;
  font-weight: bold;
  font-size: 1.4rem;
  padding: 8px;
}

.content-warp {
  min-height: calc(100vh - 100px);
  padding: 20px 20px 160px;
  background-color: #e2e2e2;
}
@media (max-width: 1024px) {
  .content-warp {
    padding: 30px 30px 100px;
  }
}
@media (max-width: 600px) {
  .content-warp {
    padding: 5vw 5vw 100px;
  }
}

.btn-col2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.btn-col2 > .btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(50% - 5px);
  padding: 15px 10px;
}
.btn-col2 .btn + .btn {
  margin-left: 10px;
}
.just-cont-center {
  justify-content: center;
}

@media (max-width: 600px) {
  .btn-col2 {
    flex-wrap: wrap;
  }
  .btn-col2 .btn {
    width: 100%;
  }
  .btn-col2 .btn + .btn {
    margin: 10px 0 0;
  }
}

.button_regist > span {
  pointer-events: none;
}
.button_regist:hover {
  background-color: #1698d9 !important;
}

.validityBtn {
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  background-color: #16b2d9;
}
.validityBtn:hover {
  opacity: 0.6;
}
.validityBtn:disabled {
  color: #333 !important;
  background-color: #999;
  opacity: 0.3;
  cursor: not-allowed;
}

.deleteBtn {
  border-radius: 4px;
  color: white;
  font-weight: bold;
  background-color: #bf0000;
}

.deleteBtn:hover {
  background-color: #8c0707;
}

.deleteBtn:disabled {
  color: #333 !important;
  background-color: #999;
  opacity: 0.3;
  cursor: not-allowed;
}
.cancelBtn {
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  background-color: #999;
}
.cancelBtn:hover {
  background-color: #666;
}

@media (max-width: 600px) {
  .el-input--prefix .el-input__inner {
    padding-left: 22px;
    padding-right: 10px;
  }
  .el-input__icon {
    width: 14px;
  }
}

.normal-txt {
  line-height: 1.4;
  font-size: 1.4rem;
}

.-spView {
  display: none;
}

@media (max-width: 1024px) {
  .-tabNone {
    display: none !important;
  }
}
@media (max-width: 600px) {
  .-spView {
    display: block;
  }
  .-spNone {
    display: none !important;
  }
}

.none {
  display: none !important;
}

/******************************
 local nav
******************************/

.local-nav {
  display: flex;
  font-size: 1.4rem;
  background: #fff url(../assets/img/bg_light-gray.png) repeat-x 0 bottom;
  background-size: 100% 3px;
}
.local-nav > li {
  width: auto;
}
.local-nav > li > .item {
  display: block;
  padding: 20px 20px;
  white-space: nowrap;
  cursor: pointer;
}
.local-nav > li.active > .item,
.local-nav > li:hover > .item {
  color: #1899d6;
  font-weight: bold;
  border-bottom: 3px solid #1899d6;
}

@media (max-width: 600px) {
  .local-nav {
    overflow-x: scroll;
  }
  .local-nav > li > .item {
    padding: 20px 15px;
  }
}

/******************************
 modal window
******************************/

.right-top {
  width: 22px;
  color: #fff;
  cursor: pointer;
}
.right-top:hover {
  transition: 0.3s ease;
  opacity: 0.6;
}

.modal-container {
  margin: 0;
  padding: 0;
}
.modal-enter-from .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.modal-container .content .body {
  padding-bottom: 100px;
}

.modal-window {
  display: inline-block;
  margin: 0 10px;
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  background-color: #fff;
}
.modal-window.-typeA {
  max-width: 500px;
}
.modal-window.-typeB {
  max-height: 90vh;
}

.modal-window > .header {
  position: relative;
  padding: 20px;
  border-bottom: solid 1px;
  color: #1899d6;
}
.modal-window > .header > .title {
  padding: 0 20px;
  font-size: 1.4rem;
  font-weight: bold;
}
.modal-window .body {
  position: relative;
  overflow-y: auto;
  max-height: 76vh;
  padding: 50px 20px;
}
.modal-window > .body > .message {
  line-height: 1.5;
  font-size: 1.4rem;
}
.modal-window.-typeB > .body {
  overflow-y: auto;
  max-height: calc(90vh - 55px);
  padding: 40px;
  text-align: left;
}

@media (min-width: 1025px) {
  .modal-window.-typeA {
    min-width: 500px;
  }
  .modal-window.-typeB {
    margin-top: 30px;
  }
}

@media (max-width: 1024px) {
  .modal-window.-typeB {
    width: calc(100% - 20px);
    max-height: initial;
  }
  .modal-window.-typeB > .body {
    max-height: initial;
    padding: 20px;
  }
}

.modal-container .content {
  width: 90vw;
}

.modal-window > .message {
  font-size: 1.2rem;
}

.modal-window > .btn-col2 {
  padding: 0 10px 10px;
}

@media (max-width: 600px) {
  .modal-window .body {
    position: relative;
    overflow-y: auto;
    max-height: 50vh;
    padding: 50px 20px;
  }
}

/******************************
 form ui
******************************/

.context-menu {
  border: 1px solid #adadad;
}

.txt-warning {
  color: #c31e1e;
}

/******************************
 option style
******************************/

.-fBold {
  font-weight: bold;
}
.-taC {
  text-align: center;
}

.-mb10 {
  margin-bottom: 10px;
}
.-mb20 {
  margin-bottom: 20px;
}
.-mb30 {
  margin-bottom: 30px;
}
.-mb40 {
  margin-bottom: 40px;
}

.-noscroll {
  position: fixed;
  width: 100%;
}

hr.line {
  border: none;
  border-bottom: 1px solid #e2e2e2;
}

/******************************
 common style
******************************/

.page-back {
  margin-bottom: 40px;
}

.registration-area {
  margin-bottom: 20px;
}
@media (max-width: 1024px) {
  .registration-area {
    margin-bottom: 30px;
  }
}
@media (max-width: 600px) {
  .registration-area {
    margin-bottom: 5vw;
  }
}
@media (max-width: 1024px) {
  .responsive-overflow {
    overflow-x: auto;
  }
}

.table-typeA {
  width: 100%;
  text-align: left;
}
.table-typeA .thead .item-th {
  padding: 10px 20px;
  text-align: left;
  background-color: #e2e2e2;
}
.table-typeA .tbody .row {
  border-bottom: 1px solid #e2e2e2;
}
.table-typeA .tbody .item-td {
  padding: 20px;
}
.table-typeA .tbody .item-btns {
  text-align: right;
}
.table-typeA .tbody .item-btns .btn + .btn {
  margin-left: 6px;
}

@media (max-width: 600px) {
  .table-typeA {
    font-size: 1rem;
  }
  .table-typeA .thead {
    display: none;
  }
  .table-typeA .tbody .row {
    display: block;
    border-bottom: none;
  }
  .table-typeA .tbody .row:not(:last-child) {
    margin-bottom: 30px;
  }
  .table-typeA .tbody .item-td {
    display: flex;
    align-items: stretch;
    padding: 0;
    border: 1px solid #d1d1d1;
    border-bottom: none;
  }
  .table-typeA .tbody .item-td p {
    padding: 15px 10px;
  }
  .table-typeA .tbody .item-td:not(.item-btns):before {
    content: attr(data-label);
    /* min-width: 150px; */
    padding: 15px 10px;
    border-right: 1px solid #d1d1d1;
    font-weight: bold;
    background-color: #ebebeb;
  }
  .table-typeA .tbody .item-btns {
    justify-content: center;
    padding: 15px 15px 0;
    border-right: none;
    border-left: none;
    font-size: 1.2rem;
  }
}

.table-typeB {
  width: 100%;
  text-align: left;
}
.table-typeB .thead .item-th {
  padding: 10px 20px;
  text-align: left;
  background-color: #e2e2e2;
}
.table-typeB .tbody .row {
  border-bottom: 1px solid #e2e2e2;
}
.table-typeB .tbody .item-td {
  padding: 20px;
}
.table-typeB .tbody .item-btns {
  text-align: right;
}
.table-typeB .tbody .item-btns .btn + .btn {
  margin-left: 6px;
}
